<template>
    <!-- Main section -->
    <section
        :class="[{'hero-bg': !isMobile(), 'hero-bg-mobile': isMobile() }]"
        class="pb-[10%] h-auto">
        <section class="h-auto">
            <!-- purchase history hidden -->
            <div 
                class="font-oxanium flex flex-col justify-center items-center pt-24 md:pt-40 lg:pt-36 2xl:pt-52">
                <h2 v-motion-pop-visible class="text-4xl md:text-5xl lg:text-6xl 2xl:text-7xl text-white uppercase font-bold mb-5 sm:mb-7 md:mb-[2%]">Store</h2>
                <div v-motion-pop-visible class="hidden lg:block w-[1180px] h-[350px] store-frame-bg">
                    <div class="flex flex-row justify-start items-center">
                        <div class="flex flex-col items-start ml-14 gap-2 w-[70%] mt-4">
                            <div class="flex flex-row" style="font-family: Oxanium, sans-serif;">
                                <img src="../assets/ballyboy_logo.png" class="w-[210px]">
                            </div>
                            <div class="flex flex-col gap-3" style="font-family: Oxanium, sans-serif;">
                                <span class="text-4xl font-bold text-white text-left uppercase">Earn Exclusive Store Reward Points!</span>
                                <ul class="list-disc text-white text-sm font-oxanium uppercase pl-5 space-y-1">
                                    <li>Competing on the monthly leaderboards</li>
                                    <li>Engaging in Kick Chat</li>
                                    <li>Winning tournaments on stream</li>
                                    <li>Completing Bally_Boy's custom challenges</li>
                                </ul>
                                <span class="text-2xl font-bold text-white text-left uppercase">Start earning and redeem your rewards today!</span>
                            </div>
                        </div>
                    </div>
                </div>
                    <div class="block lg:hidden">
                        <div class="flex flex-row justify-start items-center">
                            <div class="flex flex-col items-center gap-6">
                                <div class="flex flex-row" style="font-family: Oxanium, sans-serif;">
                                    <img src="../assets/ballyboy_logo.png" class="w-[210px]">
                                </div>
                                <div class="flex flex-col gap-2" style="font-family: Oxanium, sans-serif;">
                                    <span class="text-2xl font-bold text-white uppercase text-center">Earn Exclusive Store Reward Points!</span>
                                    <ol class="lg:list-disc text-white text-sm font-oxanium uppercase px-10 lg:pl-5 space-y-1 text-center lg:text-left">
                                        <li>• Competing on the monthly leaderboards</li>
                                        <li>• Engaging in Kick Chat</li>
                                        <li>• Winning tournaments on stream</li>
                                        <li>• Completing Bally_Boy's custom challenges</li>
                                    </ol>
                                    <span class="text- font-bold text-white text-center uppercase">Start earning and <br> redeem your rewards today!</span>
                                </div>
                            </div>
                        </div>
                    </div>
                <div class="flex justify-center flex-col align-center container mx-auto max-w-[1200px]" id="store">
                    <div class="store-header flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col flex-row xl:justify-between md:justify-around 2xl:justify-between lg:justify-around sm:justify-around max-w-[1270px] mb-7 md:mb-8 lg:mb-14 items-center ">
                        <div v-motion-pop-visible class="mt-6">
                            <p class="uppercase text-blue-500 font-bold text-[20px]">points: <span
                                    class="uppercase font-bold text-white text-[30px] sm:text-[36px]">{{ $store.state.user != null ? userPoints : '---'}} </span></p>
                        </div>
                        <div v-motion-pop-visible class=" z-10 md:flex-row flex flex-col item-center mt-4 gap-4">
                            <!-- <div class="relative inline-block text-left font-inter">
                                <div class="pr-4">
                                    <button @click="CategoryOpen = !CategoryOpen" type="button"
                                        :class="['inline-flex justify-center w-full px-4 py-2 border-2 text-white rounded text-normal xs:text-sm shadow-sm hover:border-sky-600 ', CategoryOpen ? 'border-sky-600 border-2' : 'border-white']"
                                        id="options-menu">
                                        Sort by Category
                                        <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd"
                                                d="M5.292 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </button>
                                </div>
                                <div v-if="CategoryOpen"
                                    class="origin-top-left absolute mt-2 w-48 rounded bg-[#212830] border border-white z-10">
                                    <div class="py-1">
                                        <a href="#"
                                            class="block px-4 py-2 text-sm text-white hover:bg-sky-600 ">Option
                                            1</a>
                                        <a href="#"
                                            class="block px-4 py-2 text-sm text-white hover:bg-sky-600 ">Option
                                            2</a>
                                        <a href="#"
                                            class="block px-4 py-2 text-sm text-white hover:bg-sky-600 ">Option
                                            3</a>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="relative inline-block text-left font-inter">
                                <div class="pr-4">
                                    <button @click="PriceOpen = !PriceOpen" type="button" placeholder="Sort by Price"
                                        :class="['inline-flex justify-center w-full px-4 py-2 border-2 text-white rounded text-normal xs:text-sm shadow-sm hover:border-sky-600 ', PriceOpen ? 'border-sky-600 border-2' : 'border-white']"
                                        id="options-menu">
                                        Sort by Price
                                        <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd"
                                                d="M5.292 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </button>
                                </div>
                                <div v-if="PriceOpen"
                                    class="origin-top-left absolute mt-2 w-48 rounded bg-[#212830] border border-white z-10">
                                    <div class="py-1">
                                        <a href="#"
                                            class="block px-4 py-2 text-sm text-white hover:bg-sky-600 ">Option
                                            1</a>
                                        <a href="#"
                                            class="block px-4 py-2 text-sm text-white hover:bg-sky-600 ">Option
                                            2</a>
                                        <a href="#"
                                            class="block px-4 py-2 text-sm text-white hover:bg-sky-600 ">Option
                                            3</a>
                                    </div>
                                </div>
                            </div> -->
                            <form class="max-w-sm mx-auto">
                                <div class="relative">
                                    <select v-model="sortDirection" @change="sortByPoints" class="bg-[#212830] border-2 border-gray-300 text-white rounded focus:ring-blue-500 focus:border-blue-500 block w-full py-2 pl-3 pr-10 appearance-none hover:border-sky-600">
                                        <option :value="null" disabled selected class="text-xs md:text-sm mr-3">Sort by Points</option>
                                        <option value="asc" class="text-xs md:text-sm mr-3">Points Ascending</option>
                                        <option value="desc" class="text-xs md:text-sm mr-3">Points Descending</option>
                                    </select>
                                    <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                                        <svg class="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                        </svg>
                                    </div>
                                </div>
                            </form>
                            <button v-if="$store.state.user != null" @click="initTransaction()" class="px-3 py-2 text-xs transition-all bg-[#1689DB] border border-[#1689DB] rounded-md shadow-2xl ease-in-out hover:scale-105 duration-200">
                                <p class="tracking-widest text-sm font-bold uppercase text-white">Purchase History</p>
                            </button>
                        </div>
                    </div>
                    <div class="store-body h-auto font-oxanium md:flex md:justify-center m-auto">
                        <div v-if="loadingStore == true" class="flex justify-center mt-14">
                            <div class="w-24 h-24 rounded-full animate-spin
                                border-y-8 border-solid border-white border-t-transparent">
                            </div>
                        </div>
                        <div v-if="loadingStore == false && storeData.length == 0" class="text-center text-white text-2xl md:text-4xl md:mt-24 font-bold">No Store Products yet..</div>
                        <div v-if="loadingStore == false && storeData.length > 0" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 max-w-[1270px] mx-auto">
                            <div v-motion-pop-visible v-for="(store, index) in storeData" :key="store" @click="$store.state.user != null ?  initPurchase(store) : isLogin = true" class="dynamic-item">
                                <div class="store-card-item-header w-[300px] h-auto max-h-[440px] mb-40 relative">
                                    <img class="w-full h-[300px] object-cover" :src="getColumnImage(index)" alt="">
                                    <div class="item-details absolute top-[70%] right-0 left-0 w-[278px] m-auto text-left h-[252px] bg-black rounded-md">
                                        <p class="uppercase font-bold text-base text-center xs:py-3 xs:px-2 text-[#FFDD15] lg:py-5 lg:px-7">{{ store.title }}</p>
                                        <div class="flex flex-row items-center py-2 px-3">
                                            <img class="width-[18px] h-[18px]" src="../assets/gift-icon.png" alt="">
                                            <p class="uppercase text-[13px] font-semibold text-left text-white pl-2">{{ store.description }}</p>
                                        </div>
                                        <div class="flex flex-row items-center px-3">
                                            <img class="width-[18px] h-[18px]" src="../assets/info-circle-icon.png" alt="">
                                            <p class="uppercase text-[13px] font-semibold text-left text-white pl-2">{{ store.rewardsCondition}}</p>
                                        </div>
                                        <div class="flex flex-col absolute w-full bottom-0">
                                            <a 
                                            :class="[`text-white bg-gradient-to-r hover:bg-gradient-to-l font-bold text-base py-4 px-14 relative uppercase -mt-[75px] text-center cursor-pointer`, 
                                            {'clip-path-button-left from-[#F09C1E] to-[#FFDD15]': index % 4 === 0, 'clip-path-button-right from-[#F09C1E] to-[#FFDD15]': index % 4 === 3, 'from-[#016D3A] to-[#01D370]': index % 4 === 1, 'from-[#165381] to-[#208EE2]': index % 4 === 2}]">
                                                {{ $store.state.user != null ?  store.requiredPoints + ' Points' : 'Login to buy'}}   
                                                <!-- conditional if its login? -->
                                                <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- modal here -->
                     <!-- islogin modal -->

                    <div v-show="isLogin == true" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true"> 
                        <div class="fixed inset-0 bg-black bg-opacity-70 transition-opacity" aria-hidden="true"></div>
                        <div class="fixed inset-0 z-10 w-screen overflow-y-auto" style="font-family: Oxanium, sans-serif;">
                            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <div v-motion-pop-visible class="relative overflow-hidden text-center shadow-xl sm:my-8 sm:w-full sm:max-w-xl">
                                <div class="cards-frame px-10 pb-4 pt-5 sm:pt-10 sm:px-14 sm:pb-10">
                                <div class="mt-3 text-center sm:mt-0 sm:text-center">
                                    <h3 class="text-xl lg:text-2xl font-semibold leading-6 text-white uppercase" id="modal-title">LOGIN REQUIRED TO MAKE A PURCHASE</h3>
                                    <div class="mt-2">
                                        <p class="text-white text-sm">PLEASE LOGIN TO BALLYBOY.STORE USING YOUR DISCORD ACCOUNT</p>
                                    </div>
                                </div>
                                <div class="hidden sm:flex mt-8 flex-row justify-center gap-10 mb-2" style="font-family: Oxanium, sans-serif;">
                                    <button @click="isLogin = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-16 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase clip-path-button-left main-btn">
                                    Cancel
                                    <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                                    <span class="absolute inset-y-0 left-0 -mb-[20px] bg-transparent border-r-2 border-white transform skew-x-[38deg] z-0 main-span"></span>
                                    </button>
                                    <button @click="handleLogin()" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-16 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase clip-path-button-right main-btn">
                                    Login
                                    <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                                    <span class="absolute inset-y-0 right-0 -mb-[20px] bg-transparent border-r-2 border-white transform skew-x-[143deg] z-0 leaderboard-span main-span"></span>
                                    </button>
                                </div>
                                <div class="flex sm:hidden mt-4 flex-col justify-center" style="font-family: Oxanium, sans-serif;">
                                    <button @click="handleLogin()" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-10 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase main-btn">
                                    Login
                                    <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                                    </button>
                                    <button @click="isLogin = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-14 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase main-btn">
                                    Cancel
                                    <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                                    </button>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div v-show="isPurchase == true" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true"> 
                        <div class="fixed inset-0 bg-black bg-opacity-70 transition-opacity" aria-hidden="true"></div>
                        <div class="fixed inset-0 z-10 w-screen overflow-y-auto" style="font-family: Oxanium, sans-serif;">
                            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <div v-motion-pop-visible class="relative overflow-hidden text-center shadow-xl sm:my-8 sm:w-full sm:max-w-xl">
                                <div class="cards-frame px-10 pb-4 pt-5 sm:pt-10 sm:px-14 sm:pb-10">
                                <div class="mt-3 text-center sm:mt-0 sm:text-center">
                                    <h3 class="text-xl lg:text-2xl font-semibold leading-6 text-white uppercase" id="modal-title">are you sure you'd like to proceed with this purchase?</h3>
                                    <div class="mt-4">
                                        <p class="text-white text-sm uppercase">please note that all purchases made after this step are final.</p>
                                        <p class="text-white text-sm uppercase">you'll recieved a ticket number for your reference.</p>
                                        <p class="text-white text-sm uppercase">for any assistance, head over to discord and contact our support team.</p>
                                    </div>
                                </div>
                                <div class="hidden sm:flex mt-8 flex-row justify-center gap-10 mb-2" style="font-family: Oxanium, sans-serif;">
                                    <button v-if="loadingPurchase == false" @click="isPurchase = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-16 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase clip-path-button-left main-btn">
                                    Cancel
                                    <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                                    <span class="absolute inset-y-0 left-0 -mb-[20px] bg-transparent border-r-2 border-white transform skew-x-[38deg] z-0 main-span"></span>
                                    </button>
                                    <button v-if="loadingPurchase == false" @click="purchaseProduct()" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-16 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase clip-path-button-right main-btn">
                                        ConFirm
                                        <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                                        <span class="absolute inset-y-0 right-0 -mb-[20px] bg-transparent border-r-2 border-white transform skew-x-[140deg] z-0 leaderboard-span main-span"></span>
                                    </button>
                                    <div v-if="loadingPurchase == true" class="w-14 h-14 rounded-full animate-spin
                                        border-y-8 border-solid border-white border-t-transparent">
                                    </div>
                                </div>
                                <div class="flex sm:hidden mt-4 flex-col justify-center items-center" style="font-family: Oxanium, sans-serif;">
                                    <button v-if="loadingPurchase == false" @click="purchaseProduct()" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-10 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase main-btn">
                                        Confirm
                                        <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                                    </button>
                                    <button v-if="loadingPurchase == false" @click="isPurchase = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-14 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase main-btn">
                                        Cancel
                                        <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                                    </button>
                                    <div v-if="loadingPurchase == true" class="w-14 h-14 rounded-full animate-spin
                                        border-y-8 border-solid border-white border-t-transparent">
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div v-show="isDonePurchase == true" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true"> 
                        <div class="fixed inset-0 bg-black bg-opacity-70 transition-opacity" aria-hidden="true"></div>
                        <div class="fixed inset-0 z-10 w-screen overflow-y-auto" style="font-family: Oxanium, sans-serif;">
                            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <div v-motion-pop-visible class="relative overflow-hidden text-center shadow-xl sm:my-8 sm:w-full sm:max-w-xl">
                                <div class="cards-frame px-10 pb-4 pt-5 sm:pt-10 sm:px-14 sm:pb-10">
                                <div class="mt-3 text-center sm:mt-0 sm:text-center">
                                    <h3 class="text-xl lg:text-2xl font-semibold leading-6 text-white uppercase" id="modal-title">Thank you for your purchase!</h3>
                                    <p class="uppercase text-base xs:text-xs text-white sm:text-sm py-5 sm:py-3 xs:py-1 mt-2">Your ticket is:</p>
                                    <h3 class="text-base md:text-xl font-semibold leading-6 text-white uppercase" id="modal-title">{{ this.purchaseTicket }}</h3>
                                    <div class="mt-8">
                                        <p class="text-white text-sm uppercase"><span class="font-bold uppercase text-white">NOTE: </span>copy the ticket and head over to discord and contact support for assistance.</p>
                                    </div>
                                </div>
                                <div class="hidden sm:flex mt-8 flex-row justify-center gap-10 mb-2" style="font-family: Oxanium, sans-serif;">
                                    <button @click="isDonePurchase = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-16 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase  main-btn">
                                    Close
                                    <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                                    </button>
                                </div>
                                <div class="flex sm:hidden mt-4 flex-col justify-center" style="font-family: Oxanium, sans-serif;">
                                    <button @click="isDonePurchase = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-14 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase main-btn">
                                    Close
                                    <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                                    </button>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div v-show="transactionDialog == true" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true"> 
                        <div class="fixed inset-0 bg-black bg-opacity-70 transition-opacity" aria-hidden="true"></div>
                        <div class="fixed inset-0 z-10 w-screen overflow-y-auto font-oxanium">
                            <div class="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
                                <div v-motion-pop-visible class="bg-[#151515] relative overflow-hidden rounded-lg text-left shadow-xl sm:my-8 sm:w-full sm:max-w-[1300px]">
                                    <div class="px-4 pb-6 pt-6 sm:p-6 sm:pb-4 md:mx-2">
                                        <div class="flex flex-col items-start gap-4 h-[400px] md:h-[600px] lg:h-[450px] overflow-y-auto" style="font-family: Poppins, system-ui;">
                                            <div v-if="transactionData.length == 0" class="w-full bg-black p-4">
                                                <div v-if="loadingPurchaseHistory == true" class="flex flex-row justify-between text-white">
                                                    Loading purchase history...
                                                </div>
                                                <div v-else class="flex flex-row justify-between text-white">
                                                    No purchase transaction history yet
                                                </div>
                                            </div>
                                            <div v-for="t in transactionData" :key="t" class="w-full bg-black p-4">
                                                <!-- Adjusted grid for better spacing -->
                                                <div class="md:grid hidden grid-cols-[3fr_3fr_0.5fr_1fr_1fr] gap-4">
                                                    <div class="text-white truncate whitespace-nowrap">{{ t.ticketNumber }}</div>
                                                    <div class="text-white truncate whitespace-nowrap">{{ t.product.title }}</div>
                                                    <div class="text-white truncate whitespace-nowrap">{{ t.amount }}</div>
                                                    <div class="uppercase truncate whitespace-nowrap" :class="statusClass(t.status)">{{ t.status }}</div>
                                                    <div class="text-white truncate whitespace-nowrap">{{ formatDateTime2(t.createdAt) }}</div>
                                                </div>
                                                <div class="md:hidden grid grid-rows-5 gap-4">
                                                    <div class="text-white truncate whitespace-nowrap">{{ t.ticketNumber }}</div>
                                                    <div class="text-white truncate whitespace-nowrap">{{ t.product.title }}</div>
                                                    <div class="text-white truncate whitespace-nowrap">{{ t.amount }}</div>
                                                    <div class="uppercase truncate whitespace-nowrap" :class="statusClass(t.status)">{{ t.status }}</div>
                                                    <div class="text-white truncate whitespace-nowrap">{{ formatDateTime2(t.createdAt) }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="px-4 py-4 md:flex md:flex-row md:justify-end md:px-10 md:mb-6 font-oxanium">
                                        <button @click="transactionDialog = false" type="button" class="inline-flex w-full justify-center rounded-md px-14 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase main-btn">
                                            Close
                                            <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                                        </button>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>

                    <div v-show="isNotPurchase == true" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true"> 
                        <div class="fixed inset-0 bg-black bg-opacity-70 transition-opacity" aria-hidden="true"></div>
                        <div class="fixed inset-0 z-10 w-screen overflow-y-auto" style="font-family: Oxanium, sans-serif;">
                            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <div v-motion-pop-visible class="relative overflow-hidden text-center shadow-xl sm:my-8 sm:w-full sm:max-w-xl">
                                <div class="cards-frame px-10 pb-4 pt-5 sm:pt-10 sm:px-14 sm:pb-10">
                                <div class="mt-3 text-center sm:mt-0 sm:text-center">
                                    <h3 class="text-xl lg:text-xl font-semibold leading-6 text-white uppercase" id="modal-title">You dont have enough points to complete this purchase</h3>
                                </div>
                                <div class="hidden sm:flex mt-8 flex-row justify-center gap-10 mb-2" style="font-family: Oxanium, sans-serif;">
                                    <button @click="isNotPurchase = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-16 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase  main-btn">
                                    Close
                                    <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                                    </button>
                                </div>
                                <div class="flex sm:hidden mt-4 flex-col justify-center" style="font-family: Oxanium, sans-serif;">
                                    <button @click="isNotPurchase = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-14 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase main-btn">
                                    Close
                                    <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                                    </button>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>


</template>

<script>
import axios from 'axios';
import moment from 'moment';
export default {
    data(){
        return {
            storeData: [],
            transactionData: [],
            userPoints: 0,
            productToPurchase: null,
            purchaseTicket: null,

            loadingStore: false,
            loadingPurchase: false,
            loadingPurchaseHistory: false,
            sortDirection: null,

            isLogin: false,
            isPurchase: false,
            isDonePurchase: false,
            isNotPurchase: false,
            transactionDialog: false,

            PriceOpen: false,
            CategoryOpen: false,

            productItems: [{}],
        }
    },
    methods: {
        isMobile() {
            return window.innerWidth < 1024;
        },
        getColumnImage(index){
            const images = {
                0: require('../assets/store-item-yellow.png'),
                1: require('../assets/store-item-blue.png'),
                2: require('../assets/store-item-green.png'),
                3: require('../assets/store-item-green.png'),
            }
            const keys = index % 4;
            return images[keys];
        },
        
        //store methods
        initUserPoints(){
            if(this.$store.state.user == null) return;

            const token = localStorage.getItem('ballyboy-access-token');
            axios.get(process.env.VUE_APP_BASE_API + '/api/user/points', {
                headers: {
                    'x-api-key': process.env.VUE_APP_X_API_KEY,
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data) {
                    this.userPoints = res.data.points
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                            localStorage.removeItem("vuex");
                            localStorage.removeItem("ballyboy-access-token");
                            window.location.reload();
                    } else {
                        console.log(`Error: ${error.response.status}`);
                    }
                } 
                else if (error.request) {
                    console.log('No response received', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        },
        initProducts() {
            this.loadingStore = true
            axios.get(process.env.VUE_APP_BASE_API + '/api/store/products', {
                headers: {
                    'x-api-key': process.env.VUE_APP_X_API_KEY,
                },
            })
            .then((res) => {
                if (res.data) {
                    this.storeData = res.data.products
                    this.loadingStore = false
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                            localStorage.removeItem("vuex");
                            localStorage.removeItem("ballyboy-access-token");
                            window.location.reload();
                    } else {
                        console.log(`Error: ${error.response.status}`);
                    }
                } 
                else if (error.request) {
                    console.log('No response received', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
		},
        initTransaction(){
            this.transactionDialog = true
            this.loadingPurchaseHistory = true
            const token = localStorage.getItem('ballyboy-access-token');
            axios.get(process.env.VUE_APP_BASE_API + '/api/user/purchase/transactions', {
                headers: {
                    'x-api-key': process.env.VUE_APP_X_API_KEY,
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data) {
                    this.transactionData = res.data.purchaseTransactions
                    this.loadingPurchaseHistory = false
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                            localStorage.removeItem("vuex");
                            localStorage.removeItem("ballyboy-access-token");
                            window.location.reload();
                    } else {
                        console.log(`Error: ${error.response.status}`);
                    }
                } 
                else if (error.request) {
                    console.log('No response received', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        },
        initPurchase(product){
            if(product.requiredPoints > this.userPoints){
                this.isNotPurchase = true
            }
            else{
                this.isPurchase = true
                this.productToPurchase = product
            }
        },
        purchaseProduct(){
            const token = localStorage.getItem('ballyboy-access-token');
            this.loadingPurchase = true
            let formData = {
                productId: this.productToPurchase._id
            }
            axios.post(process.env.VUE_APP_BASE_API + '/api/store/purchase', formData, {
                headers: {
                    'x-api-key': process.env.VUE_APP_X_API_KEY,
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data) {
                    this.purchaseTicket = res.data.ticket
                    this.isPurchase = false
                    this.loadingPurchase = false
                    this.isDonePurchase = true
                    this.initUserPoints()
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                            localStorage.removeItem("vuex");
                            localStorage.removeItem("ballyboy-access-token");
                            window.location.reload();
                    } else {
                        console.log(`Error: ${error.response.status}`);
                    }
                } 
                else if (error.request) {
                    console.log('No response received', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        },
        statusClass(status) { //might change
            switch (status) {
                case 'inprogress':
                    return 'text-blue-600';
                case 'completed':
                    return 'text-green-600';
                case 'pending':
                    return 'text-yellow-600';
                case 'declined':
                    return 'text-red-600';
                default:
                    return '';
            }
        },
        sortByPoints() {
            if (this.sortDirection === 'asc') {
                this.storeData.sort((a, b) => a.requiredPoints - b.requiredPoints);
            } else if (this.sortDirection === 'desc') {
                this.storeData.sort((a, b) => b.requiredPoints - a.requiredPoints);
            }
        },
        formatDateTime2(date, format = 'D MMM YYYY HH:mm') {
            if (date) {
                return moment(date).format(format);
            }
        },
        handleLogin() {
            const loginUrl = `${process.env.VUE_APP_BASE_API}/api/auth/discord`
            window.location.href = loginUrl
        },
    },
    mounted(){
        this.initProducts();
        this.initUserPoints();
    },
}
</script>

<style  scoped>
.hero-bg {
  background-image: url("../assets/hero-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.hero-bg-mobile {
  background-image: url("../assets/hero-bg-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.item-details {
    clip-path: polygon(20px 0%, calc(100% - 20px) 0, 150% 100%, -50% 100%);
}
/* .trim-bt-l{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 20px 100%, 0 80%);
}
.trim-bt-r {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
} */

.modal-clip-path {
    clip-path: polygon(20px 0%, calc(100% - 20px) 0, 150% 100%, -50% 100%);
}

.cards-frame {
  background-image: url('../assets/cards-frame.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.store-frame-bg {
  background-image: url('../assets/store-frame-bg.png');
  background-repeat: no-repeat;
  background-size: contain;
}
/* .modal-clip-path::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border: 1px solid #1294F0;
    clip-path: polygon(20px 0%, calc(100% - 20px) 0, 150% 100%, -50% 101%);
    pointer-events: none;
} */

</style>