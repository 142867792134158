<template>
    <section :class="{'hero-bg': !isMobile(), 'hero-bg-mobile': isMobile() }" class="min-h-screen">
        <div class="flex flex-col justify-center items-center pt-28 md:pt-36 lg:pt-40 lg:pb-20" style="font-family: Oxanium, sans-serif;">
            <div v-motion-pop-visible class="text-center text-white uppercase">
                <p class="text-4xl md:text-5xl lg:text-6xl font-bold">Giveaways</p>
            </div>
            <div v-motion-pop-visible class="hidden lg:block w-[1180px] h-[350px] giveaways-frame-bg mt-10">
                <div class="flex flex-row justify-start items-center">
                    <div class="flex flex-col items-start ml-14 gap-2 w-[70%] mt-4">
                        <div class="flex flex-row" style="font-family: Oxanium, sans-serif;">
                            <img src="../assets/ballyboy_logo.png" class="w-[210px]">
                        </div>
                        <div class="flex flex-col gap-3" style="font-family: Oxanium, sans-serif;">
                            <span class="text-4xl font-bold text-white text-left uppercase">Unlock Your Chance to Win Big!</span>
                            <ul class="list-disc text-white text-sm font-oxanium uppercase pl-5 space-y-1">
                                <li>select the giveaway</li>
                                <li>Enter your unique giveaway code to secure your spot</li>
                                <li>Track your entries and watch the winners roll in</li>
                                <li>Don’t miss out on exclusive rewards and surprises!</li>
                            </ul>
                            <span class="text-2xl font-bold text-white text-left uppercase">Enter your code and join the fun today!</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block lg:hidden px-6 mt-8">
                <div class="flex flex-row justify-start items-center">
                    <div class="flex flex-col items-center gap-6">
                        <div class="flex flex-row" style="font-family: Oxanium, sans-serif;">
                            <img src="../assets/ballyboy_logo.png" class="w-[210px]">
                        </div>
                        <div class="flex flex-col gap-2" style="font-family: Oxanium, sans-serif;">
                            <span class="text-2xl font-bold text-white uppercase text-center">Unlock Your Chance to Win Big!</span>
                            <ol class="lg:list-disc text-white text-sm font-oxanium uppercase px-10 lg:pl-5 space-y-1 text-center lg:text-left">
                                <li>• select the giveaway</li>
                                <li>• Enter your unique giveaway code to secure your spot</li>
                                <li>• Track your entries and watch the winners roll in</li>
                                <li>• Don’t miss out on exclusive rewards and surprises!</li>
                            </ol>
                            <span class="text- font-bold text-white text-center uppercase">Enter your code <br> and join the fun today!</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center mt-6">
                <p class="text-[#1294F0] font-bold text-lg uppercase tracking-widest">Select</p>
                <p class="text-white font-bold text-4xl uppercase">Giveaway</p>
            </div>
            <div class="container mx-auto max-w-[1280px]">
              <div class="flex flex-col md:flex-row justify-between mt-10 mx-auto gap-3">
                <button @click="init()" class="px-3 py-2 text-xs transition-all bg-[#1689DB] border border-[#1689DB] rounded-md shadow-2xl ease-in-out hover:scale-110 duration-200 mx-10 md:ml-24 lg:ml-12">
                    <p class="tracking-widest text-sm font-bold uppercase text-white">Refresh</p>
                </button>
                <form class="max-w-sm mx-10 md:mr-24 lg:mr-12">
                    <div class="relative">
                        <select v-model="sortStatus" @change="sortByStatus()" class="bg-[#212830] border-2 border-gray-300 text-white rounded focus:ring-blue-500 focus:border-blue-500 block w-full py-2 pl-3 pr-14 appearance-none hover:border-sky-600">
                            <option value="all" disabled selected class="text-xs md:text-sm mr-3">Sort by Status</option>
                            <option value="active" class="text-xs md:text-sm mr-3">Waiting to Start</option>
                            <option value="started" class="text-xs md:text-sm mr-3">Started</option>
                            <option value="stopped" class="text-xs md:text-sm mr-3">Stopped</option>
                            <option value="ended" class="text-xs md:text-sm mr-3">Ended</option>
                        </select>
                        <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                            <svg class="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                            </svg>
                        </div>
                    </div>
                </form>
              </div>
                <div class="h-auto font-oxanium flex justify-center m-auto">
                    <div v-if="loading == true" class="flex justify-center my-14">
                        <div class="w-24 h-24 rounded-full animate-spin
                            border-y-8 border-solid border-white border-t-transparent">
                        </div>
                    </div>
                    <div v-if="loading == false && giveaways.length == 0" class="text-center text-white text-2xl md:text-4xl font-bold my-20">No giveaways created yet..</div>
                    <div v-if="loading == false && giveaways.length > 0" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 lg:gap-4 max-w-[1200px] mx-auto">
                        <div v-motion-pop-visible v-for="(giveaways, index) in giveaways" :key="giveaways" class="dynamic-item">
                            <div class="store-card-item-header w-[300px] h-auto max-h-[440px] mb-52 relative">
                                <img class="w-full h-[300px] object-cover" :src="getColumnImage(index)" alt="">
                                <div class="item-details absolute top-[70%] right-0 left-0 w-[278px] m-auto text-left h-[280px] bg-black rounded-md">
                                    <p class="uppercase font-bold text-base text-center xs:py-3 px-4 text-[#FFDD15] lg:py-5 lg:px-7">{{ giveaways.title }}</p>
                                    <div class="flex flex-row items-center px-3">
                                        <p class="uppercase text-[13px] font-black text-left text-white pl-2">Status:</p>
                                        <p class="uppercase text-[13px] font-semibold text-left text-white pl-2">{{ giveaways.status}}</p>
                                    </div>
                                    <div class="flex flex-row items-center px-3">
                                        <p class="uppercase text-[13px] font-black text-left text-white pl-2">Created At:</p>
                                        <p class="uppercase text-[13px] font-semibold text-left text-white pl-2">{{ formatDateTime2(giveaways.createdAt)}}</p>
                                    </div>
                                    <div class="flex flex-col absolute w-full bottom-0">
                                        <button v-if="$store.state.user == null"
                                        @click="isLogin = true"
                                        :class="['text-white bg-gradient-to-r hover:bg-gradient-to-l font-bold text-base py-4 px-14 relative uppercase -mt-[75px] text-center cursor-pointer', {'clip-path-button-left from-[#F09C1E] to-[#FFDD15]': index % 4 === 0, 'clip-path-button-right from-[#F09C1E] to-[#FFDD15]': index % 4 === 3, 'from-[#016D3A] to-[#01D370]': index % 4 === 1, 'from-[#165381] to-[#208EE2]': index % 4 === 2}]">
                                            Login to Join
                                            <!-- conditional if its login? -->
                                            <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                                        </button>
                                        <button v-else
                                        @click="giveaways.status == 'active' ? '': showGiveawayDialog(giveaways)"
                                        :class="[`text-white bg-gradient-to-r hover:bg-gradient-to-l font-bold text-base py-4 px-14 relative uppercase -mt-[75px] text-center cursor-pointer`, 
                                        {'clip-path-button-left': index % 4 === 0, 'clip-path-button-right': index % 4 === 3, 'from-[#016D3A] to-[#01D370]': giveaways.status == 'started', 'from-[#165381] to-[#208EE2]': giveaways.status == 'active', 'from-[#DC1C13] to-[#F07470]': giveaways.status == 'stopped', 'from-[#696969] to-[#9E9E9E]': giveaways.status == 'ended', }]">
                                            <div v-if="giveaways.status == 'active'">Waiting to start</div>
                                            <div v-if="giveaways.status == 'started'">Join Giveaway</div>
                                            <div v-if="giveaways.status == 'stopped'">View Entries</div>
                                            <div v-if="giveaways.status == 'ended'">View Winners</div>
                                            <!-- conditional if its login? -->
                                            <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="givewayDialog == true" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true"> 
            <div class="fixed inset-0 bg-black bg-opacity-70 transition-opacity" aria-hidden="true"></div>
            <div class="fixed inset-0 z-10 w-screen overflow-y-auto font-oxanium">
                <div class="flex  min-h-full items-end justify-center text-center sm:items-center sm:p-0">
                    <div v-motion-pop-visible class="bg-[#151515] relative overflow-hidden rounded-lg text-left shadow-xl sm:my-8 w-full mx-8 sm:max-w-[1300px]">
                        <div class="px-4 pb-6 pt-6 sm:p-6 sm:pb-4 md:mx-2">
                            <div class="flex flex-col gap-4">
                                <div class="flex flex-col lg:flex-row items-center gap-2">
                                    <p class="uppercase text-base text-center lg:text-left text-[#Ffffff]">You are viewing: </p>
                                    <p class="uppercase font-bold text-base text-center lg:text-left text-[#FFDD15]">{{ giveawayDetails.title }}</p>
                                </div>
                                <div>
                                    <p v-if="giveawayDetails.status == 'started' && alreayJoined == false" class="uppercase font-bold text-base text-center lg:text-left text-[#Ffffff]">Enter the code to join!</p>
                                    <p v-if="giveawayDetails.status == 'stopped' && alreayJoined == false" class="uppercase font-bold text-base text-center lg:text-left text-red-600">SORRY! THIS GIVEAWAY ENDED AND YOU CAN NO LONGER JOIN</p>
                                    <p v-if="giveawayDetails.status == 'ended'" class="uppercase font-bold text-base text-center lg:text-left text-[#Ffffff]">THIS GIVEAWAY ALREADY ENDED. <br> <span class="text-white">THE WINNER/S FOR THIS GIVEAWAY IS/ARE:</span></p>
                                </div>
                                <div v-if="giveawayDetails.status != 'ended'">
                                    <p v-if="alreayJoined == true" class="uppercase font-bold text-base text-center lg:text-left text-green-600">You have succesfully joined the giveaway! <br> <span class="text-white">Entries are:</span></p>
                                </div>
                                <div v-if="giveawayDetails.status == 'started' && alreayJoined == false" class="flex flex-col lg:flex-row justify-start items-center gap-4">
                                    <input :disabled="loadingJoin" v-model="code" type="text" class="border border-[#DFE4EA] bg-[#222222] px-4 w-full h-[50px] max-w-[500px] rounded-lg text-white font-bold">
                                    <button :disabled="loadingJoin" @click="joinGiveaway()" type="button" class="inline-flex w-full justify-center rounded-md px-10 py-3.5 text-sm font-bold hover:text-white shadow-lg sm:mt-0 sm:w-auto text-white bg-gradient-to-r from-[#F09C1E] to-[#FFDD15] font-base relative uppercase main-btn">
                                        <div v-if="loadingJoin == true" role="status">
                                            <svg aria-hidden="true" class="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                            </svg>
                                        </div>
                                        <div v-else>Join Giveaway</div>
                                        <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-14"></span>
                                    </button>
                                    <p v-if="errorShow" class="uppercase text-base text-left text-red-600">{{ errorMessage }}</p>
                                </div>
                                <div class="flex flex-col items-start gap-4 h-[280px] md:h-[530px] lg:h-[380px] overflow-y-auto" style="font-family: Poppins, system-ui;">
                                    <div v-if="giveawayData.length == 0" class="w-full p-4">
                                        <div v-if="loadingGiveaway == true" class="flex flex-row justify-center">
                                            <div class="w-24 h-24 rounded-full animate-spin
                                                border-y-8 border-solid border-white border-t-transparent">
                                            </div>
                                        </div>
                                        <div v-else class="flex flex-row justify-between text-white">
                                            No record yet
                                        </div>
                                    </div>
                                    <div v-for="g in giveawayData" :key="g" class="w-full border rounded-md bg-black p-4">
                                        <!-- Adjusted grid for better spacing -->
                                        <div class="md:grid hidden grid-cols-[3fr_1fr] gap-4">
                                            <div class="text-white truncate whitespace-nowrap">{{ g.user.username }}</div>
                                            <div class="text-white truncate whitespace-nowrap">{{ formatDateTime2(g.createdAt) }}</div>
                                        </div>
                                        <div class="md:hidden grid grid-rows-2 gap-4">
                                            <div class="text-white truncate whitespace-nowrap">{{ g.user.username }}</div>
                                            <div class="text-white truncate whitespace-nowrap">{{ formatDateTime2(g.createdAt) }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="px-4 py-4 md:flex md:flex-row md:justify-end md:px-10 md:mb-6 font-oxanium">
                            <button @click="givewayDialog = false;" type="button" class="inline-flex w-full justify-center rounded-md px-14 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase main-btn">
                                Close
                                <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                            </button>
                        </div>
                    </div>  
                </div>
            </div>
        </div>

        <div v-show="isLogin == true" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true"> 
            <div class="fixed inset-0 bg-black bg-opacity-70 transition-opacity" aria-hidden="true"></div>
            <div class="fixed inset-0 z-10 w-screen overflow-y-auto" style="font-family: Oxanium, sans-serif;">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div v-motion-pop-visible class="relative overflow-hidden text-center shadow-xl sm:my-8 sm:w-full sm:max-w-xl">
                    <div class="cards-frame px-10 pb-4 pt-5 sm:pt-10 sm:px-14 sm:pb-10">
                    <div class="mt-3 text-center sm:mt-0 sm:text-center">
                        <h3 class="text-xl lg:text-2xl font-semibold leading-6 text-white uppercase" id="modal-title">LOGIN REQUIRED TO JOIN A GIVEAWAY</h3>
                        <div class="mt-2">
                            <p class="text-white text-sm">PLEASE LOGIN TO BALLYBOY.STORE USING YOUR DISCORD ACCOUNT</p>
                        </div>
                    </div>
                    <div class="hidden sm:flex mt-8 flex-row justify-center gap-10 mb-2" style="font-family: Oxanium, sans-serif;">
                        <button @click="isLogin = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-16 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase clip-path-button-left main-btn">
                        Cancel
                        <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                        <span class="absolute inset-y-0 left-0 -mb-[20px] bg-transparent border-r-2 border-white transform skew-x-[38deg] z-0 main-span"></span>
                        </button>
                        <button @click="handleLogin()" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-16 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase clip-path-button-right main-btn">
                        Login
                        <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                        <span class="absolute inset-y-0 right-0 -mb-[20px] bg-transparent border-r-2 border-white transform skew-x-[143deg] z-0 leaderboard-span main-span"></span>
                        </button>
                    </div>
                    <div class="flex sm:hidden mt-4 flex-col justify-center" style="font-family: Oxanium, sans-serif;">
                        <button @click="handleLogin()" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-10 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase main-btn">
                        Login
                        <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                        </button>
                        <button @click="isLogin = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-14 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase main-btn">
                        Cancel
                        <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                        </button>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
    export default {
        data(){
            return {
                code: '',
                loading: false,
                loadingGiveaway: false,
                loadingJoin: false,
                giveaways:[],
                savedGiveaways: [],
                giveawayDetails: [],
                giveawayStatus: 'started',
                giveawayData: [],
                givewayDialog: false,

                alreayJoined: false,
                isLogin: false,

                errorShow: false,
                errorMessage: '',

                sortStatus: 'all',
            }
        },
        methods: {
            init(){
                const token = localStorage.getItem('ballyboy-access-token');
                this.loading = true
                axios.get(process.env.VUE_APP_BASE_API + '/api/giveaways', {
                    headers: {
                        'x-api-key': process.env.VUE_APP_X_API_KEY,
                        'Authorization': `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    if (res.data) {
                        this.giveaways = res.data.giveways
                        this.savedGiveaways = res.data.giveways
                        this.sortByStatus()
                        this.loading = false
                    }
                }).catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                                localStorage.removeItem("vuex");
                                localStorage.removeItem("ballyboy-access-token");
                                window.location.reload();
                        } else {
                            console.log(`Error: ${error.response.status}`);
                        }
                    } 
                    else if (error.request) {
                        console.log('No response received', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
            },
            showGiveawayDialog(data){
                this.givewayDialog = true
                this.giveawayDetails = data
                this.giveawayData = []
                this.code = ''
                this.loadEntries(data)
            },
            loadEntries(data){
                const token = localStorage.getItem('ballyboy-access-token');
                this.loadingGiveaway = true

                if(data.status == 'ended'){
                    axios.get(process.env.VUE_APP_BASE_API + '/api/giveaways/' + data._id + '/users?isWinner=true', {
                        headers: {
                            'x-api-key': process.env.VUE_APP_X_API_KEY,
                            'Authorization': `Bearer ${token}`,
                        },
                    })
                    .then((res) => {
                        if (res.data) {
                            this.giveawayData = res.data.giveawayUsers
                            this.alreayJoined = false
                            this.loadingGiveaway = false
                        }
                    }).catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                    localStorage.removeItem("vuex");
                                    localStorage.removeItem("ballyboy-access-token");
                                    window.location.reload();
                            } else {
                                console.log(`Error: ${error.response.status}`);
                            }
                        } 
                        else if (error.request) {
                            console.log('No response received', error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                    });
                }
                else{
                    axios.get(process.env.VUE_APP_BASE_API + '/api/giveaways/' + data._id + '/users', {
                        headers: {
                            'x-api-key': process.env.VUE_APP_X_API_KEY,
                            'Authorization': `Bearer ${token}`,
                        },
                    })
                    .then((res) => {
                        if (res.data) {
                            this.giveawayData = res.data.giveawayUsers
                            this.loadingGiveaway = false
                            
                            const username = this.$store.state.user.username;
                            const usernames = this.giveawayData.map(item => item.user.username);
                            this.alreayJoined = usernames.includes(username)
                        }
                    }).catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                    localStorage.removeItem("vuex");
                                    localStorage.removeItem("ballyboy-access-token");
                                    window.location.reload();
                            } else {
                                console.log(`Error: ${error.response.status}`);
                            }
                        } 
                        else if (error.request) {
                            console.log('No response received', error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                    });
                }
                
            },
            joinGiveaway(){
                this.loadingJoin = true
                const formData = {
                    code: this.code,
                    giveawayId: this.giveawayDetails._id
                }

                if(this.code.trim() == ''){
                    this.errorShow = true
                    this.errorMessage = 'Code cannot be empty or only just spaces'
                    this.loadingJoin = false

                    setTimeout(() => {
                        this.errorShow = false;
                    }, 3000);
                }
                else{
                    const token = localStorage.getItem('ballyboy-access-token');
                    axios.post(process.env.VUE_APP_BASE_API + '/api/giveaways/join', formData, {
                        headers: {
                            'x-api-key': process.env.VUE_APP_X_API_KEY,
                            'Authorization': `Bearer ${token}`,
                        },
                    })
                    .then((res) => {
                        if (res.data) {
                            this.init()
                            this.loadEntries(this.giveawayDetails)
                            this.loadingJoin = false
                        }
                    }).catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                    localStorage.removeItem("vuex");
                                    localStorage.removeItem("ballyboy-access-token");
                                    window.location.reload();
                            }
                            if(error.response.status === 400){
                               
                               if(error.response.data.message == 'Giveaway was not started yet!'){
                                  this.init()
                                  this.loadEntries(this.giveawayDetails)
                                  this.giveawayDetails.status = 'stopped'
                                  this.loadingJoin = false
                               }
                               else {
                                  this.errorShow = true
                                  this.errorMessage = error.response.data.message
                                  this.loadingJoin = false

                                  setTimeout(() => {
                                    this.errorShow = false;
                                  }, 3000);
                               }
                               
                            } else {
                                console.log(`Error: ${error.response.status}`);
                            }
                        } 
                        else if (error.request) {
                            console.log('No response received', error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                    });
                }
                
            },
            sortByStatus() {
                if(this.sortStatus == 'all') return;
                this.giveaways = this.savedGiveaways.filter(data => data.status === this.sortStatus);
            },
            getColumnImage(index){
                const images = {
                    0: require('../assets/store-item-yellow.png'),
                    1: require('../assets/store-item-blue.png'),
                    2: require('../assets/store-item-green.png'),
                    3: require('../assets/store-item-green.png'),
                }
                const keys = index % 4;
                return images[keys];
            },
            handleLogin() {
                const loginUrl = `${process.env.VUE_APP_BASE_API}/api/auth/discord`
                window.location.href = loginUrl
            },
            isMobile() {
                return window.innerWidth < 1024;
            },
            formatDateTime2(date, format = 'MMM DD YYYY H:mm') {
                if (date) {
                    return moment(date).format(format);
                }
            }
        },
        mounted(){
            this.init()
        }
    }
</script>

<style scoped>
.hero-bg {
  background-image: url("../assets/hero-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.hero-bg-mobile {
  background-image: url("../assets/hero-bg-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.giveaways-frame-bg {
  background-image: url('../assets/giveaways-frame-bg.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.cards-frame {
  background-image: url('../assets/cards-frame.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.item-details {
    clip-path: polygon(20px 0%, calc(100% - 20px) 0, 150% 100%, -50% 100%);
}

.trim-bt-l{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 20px 100%, 0 80%);
}

.trim-bt-r {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
}
</style>